import * as React from "react"

import Layout from "../components/layout"
import {Avatar, Box, Container, Flex, Heading, Icon, Link, Text} from "@chakra-ui/react";

import { FiLinkedin } from 'react-icons/fi';
import {motion} from "framer-motion";
import SectionMore from "../components/mainPage/more/SectionMore";

const MotionHeading= motion(Heading)

const LeProjet = ({ }) => {
  return (
    <Layout title={"le projet"}>
        <Container maxW="container.xl" sx={{
                margin: `10px auto`,

                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>



            <Flex flexWrap={"wrap"} mt={'30px'} mb={8} alignItems={"center"} justifyContent={"center"}>
                <Flex width={200} maxWidth={'100%'} m={4} p={1} justifyContent={"center"}
                    bg={"bg.300"} boxShadow={'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'}
                >
                    <Box p={3}><Heading fontSize={"26px"} width={"100%"} whiteSpace={"nowrap"} lineHeight={0.7} >La Voix</Heading>
                        <Heading fontSize={"13px"} whiteSpace={"nowrap"}>Des Elections</Heading>
                    </Box>
                </Flex>
                <Box  width={750} maxWidth={'100%'}
                      m={2} p={3}>
                    <Flex sx={{justifyContent: 'flex-start'}}>
                     <Box sx={{width:'15px', bg:'bg.900'}}/>  <MotionHeading as={"h1"} sx={{}} animate={{x:-5}}>Le Projet</MotionHeading>
                    </Flex>
                    <Text sx={{borderLeftStyle:'solid',borderLeftWidth:'2px',borderLeftColor:'bg.900',p:1}} fontSize={'20px'}>
                        Ce site a été developpé sans parti pris.
                        L'objectif est de fournir une vue actualisée des messages politiques, afin d'aider à la prise de décision lors d'élections.
                        Ces données fournissent également des ensembles cohérents pour des analyses de messages politiques par des chercheurs.
                    </Text>
                </Box>
            </Flex>

            <Flex flexWrap={"wrap"} mt={['20px','85px']} mb={"30px"} alignItems={"center"} justifyContent={"center"}>
                <Flex width={200} maxWidth={'100%'} flexDirection={"column"} alignItems={"center"}  sx={{}} m={1} p={1}>
                    <Avatar size={"2xl"} src={"/img/ncv.jpeg"}/>
                    <Heading as={"h2"} fontSize={"18px"} mt={3}>Nicolas Cavallo</Heading>
                </Flex>
                <Box width={750} maxWidth={'100%'} m={2} p={3}>
                    <Flex sx={{justifyContent: 'flex-start'}}>
                     <Box sx={{width:'15px', bg:'bg.900'}}/>  <MotionHeading as={"h3"} animate={{x:-5}}>Fondateur</MotionHeading>
                    </Flex>

                    <Text  sx={{borderLeftStyle:'solid',borderLeftWidth:'2px',borderLeftColor:'bg.900',p:1}} fontSize={'20px'}>
                        Passionné par la donnée, Manager dans un cabinet de conseil Octo Technology, je me suis <strong>spécialisé dans l'analyse du langage écrit et parlé</strong> à travers de nombreux projets.
                        J'espère que ce site éclairera le débat et donnera de la visibilité au contenu politique diffusé sur internet.
                        <strong><br/>N'hésitez pas à me faire des retours !</strong>
                    </Text>
                    <Flex  alignItems={"center"} >
                        <Text color={"text.100"}>Contact :</Text>
                        <Box p={2}><Link href={"https://www.linkedin.com/in/cavallonicolas/"} target="_blank">
                  <Icon boxSize="25px" as={FiLinkedin} color="white" bg={"text.100"} opacity={0.5} borderRadius={'5px'} p={'3px'} />
                     </Link></Box>
                    </Flex>
                </Box>

            </Flex>
            <SectionMore/>
        </Container>
    </Layout>
  )
}

export default LeProjet
